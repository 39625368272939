
header {
  display: flex;
  align-items: center;
  padding: 40px 50px;
  color: white;
  background-color: #000;  
}

nav {
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  width: 100%;
}

nav h1 {
  font-size: 36px;
  font-weight: bold;
  color: #d46618; 
  margin: 0;
}

nav ul {
  list-style: none;
  display: flex;  
  gap: 20px;  
  margin: 0;
  padding: 0;
  margin-left: auto; 
}

nav ul li {
  font-size: 18px;
  font-weight: bold;
}

nav ul li a {
  text-decoration: none;
  color: #fff;  
  padding: 10px 20px;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

nav ul li a:hover {
  background-color: #d46618;  
  color: white;  
}

@media (max-width: 768px) {
  header {
    flex-direction: column;
    height: auto;
    text-align: center;
  }

  nav {
    flex-direction: column;
  }

  nav ul {
    flex-direction: column;
    gap: 10px;
  }

  nav h1 {
    margin-bottom: 20px;
  }
}
