.about-section {
  padding: 80px 20px;
  background-color: #000; 
  color: #fff;
  text-align: center;
}

.about-section h2 {
  font-size: 34px;
  color: #fff; 
  margin-bottom: 20px;
}

.about-section h4 {
  margin-top: 60px;
  font-size: 23px;
  color: #ff8c00; 
  margin-bottom: 10px;
}
.about-section p {
  color: #c1c1c1; 
  font-size: 18px;
}

.arrow {
  text-align: center;
  font-size: 30px;
  display: block;
  margin-top: 20px;
}
.subtitle {
  font-size: 24px;
  margin-bottom: 50px;
  color: #fff; 
}

.info-sections {
  max-width: 1200px;
  display: block;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto; 
  margin-top: 40px;
}

.info-part {
  background-color: #262626; 
  border-radius: 10px; 
  padding: 20px;
  margin-bottom: 20px; 
  text-align: left;
  flex: 600px;
}

.info-container {
  display: flex;
  flex-direction: column; /* 자식 요소를 세로 방향으로 정렬 */
  gap: 10px; /* 요소 간의 간격 조절 */
  margin-bottom: 10px;
}

.info-container .top-row {
  display: flex; /* 한 줄로 정렬 */
  align-items: center; /* 세로 가운데 정렬 */
  gap: 10px; /* 요소 간 간격 */
}

.info-container p {
  margin: 0; /* 여백 초기화 */
}

.number {
  background-color: #3c2e24;
  color: #ff8c00;
  padding: 5px 10px;
  border-radius: 8px;
  display: inline-block;
  margin-bottom: 0; /* 하단 여백 제거 */
  font-weight: bold;
}

.about-section h5 {
  margin-top: 0; /* 상단 여백 제거 */
  font-size: 20px;
  color: white;
  margin-bottom: 1; /* 하단 여백 제거 */
}

/* 공모 컨테이너 */
.tender-container {
  background-color: #333; 
  padding: 20px;
  width: 100%;
  height: auto; /* 높이를 자동으로 조정 */
  border-radius: 10px;
  overflow: hidden; 
  position: relative;
  display: flex;
  align-items: center; /* 수직 가운데 정렬 */
}


/* .tender-list {
  display: flex;
  flex-wrap: nowrap; 
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  animation: scroll 20s linear infinite;
} */

.tender-list {
  display: grid;
  grid-template-rows: repeat(2, 1fr); /* 항상 2행 유지 */
  grid-auto-flow: column; /* 열을 자동으로 추가 */
  gap: 20px; /* 요소 간 간격 */
  width: max-content; /* 내부 콘텐츠 크기에 맞게 확장 */
  animation: scroll 20s linear infinite;
}

/* 각 공모 정보 박스 */
.tender-box {
  display: inline-block;
    background-color: #4c4c4c;
    padding: 15px;
    border-radius: 8px;
    box-sizing: border-box;
    width: 300px;
    height: 150px; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-right: 20px;
    text-align: center;
}
.TenderRow {
  display: flex;
  gap: 20px;
  justify-content: center;
  white-space: nowrap;
  height: 150px; /* 높이를 고정 */
}
@keyframes scroll {
  0% {
      transform: translateX(100%);
  }
  100% {
      transform: translateX(-100%);
  }
}

/* 리스트 복제용 */
.tender-list .cloned {
  display: inline-block;
}


.chart-group {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.chart-container {
  background-color: #333;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* .tender-list{
  display: flex;
  flex-wrap: wrap;
  gap:10px 
} */

.tender-status {
  font-size: 14px;
  font-weight: bold;
  color: #ff8c00; 
  margin-bottom: 8px;
}

.tender-title {
  font-size: 18px;
  color: #fff;
  margin-bottom: 8px;
}

.tender-divider {
  border: none;
  border-top: 1px solid #777; 
  margin-bottom: 10px;
}

.tender-description {
  font-size: 14px;
  color: #fff;
  line-height: 1.5;
}
.ongoing {
  color: #ff8c00; 
}

.completed {
  color: #aaa; 
}

@media (max-width: 768px) {
  .info-part {
      margin-bottom: 20px; 
  }
}


.about-image {
  width: 100%;
  height: auto;
  max-width: 1200px; 
  display: block;
  margin: 0 auto;
}

.highlight-box {
  background-color: #3c2e24; /* 배경색 설정 */
  border: 2px solid #ff8c00; /* 테두리 색상 및 두께 설정 */
  padding: 20px; /* 내부 여백 설정 */
  border-radius: 8px; /* 모서리를 둥글게 설정 */
  color: #ff8c00; /* 텍스트 색상 설정 */
  text-align: center; /* 텍스트 중앙 정렬 */
  margin: 20px 0; /* 상하 마진 설정 */
}

.highlight-box h5 {
  font-size: 18px; 
  font-weight: bold; 
  margin-bottom: 10px; 
  color: #ff8c00; 
}

.highlight-box p {
  color: white; 
  font-size: 18px; 
  margin: 0; 
}