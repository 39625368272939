.services-section {
  padding: 80px 20px;
  background-color: #000;
  color: #fff;
  text-align: center;
  position: relative;
}

.services-section h2 {
  font-size: 34px;
  margin-bottom: 10px;
}

.services-section h4 {
  font-size: 23px;
  margin-bottom: 10px;
  color: #ff8c00;
}


.services-section p {
  font-size: 23px;
  margin-bottom: 20px;
  line-height: 1.6;
  color: #cacaca;
}


.services-container {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #262626; /* 메인 컨테이너 배경 */
  border: 2px solid #4c4c4c; /* 컨테이너 외곽선 */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 그림자 추가 */
}

.timeline {
  width: 30%;
  background: #262626; /* 사이드바 배경 */
  padding: 10px;
  border-right: 2px solid #4c4c4c; /* 경계선 추가 */
  box-sizing: border-box;
}

.timeline-step {
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  transition: all 0.3s ease;
  background-color: #1a1a1a;
  border: 1px solid #333;
}

.timeline-step:hover {
  background-color: #333; /* 마우스 오버 시 */
  border-color: #ff8c00;
}

.timeline-step.active {
  border: 2px solid #ff8c00;
  background-color: #4c4c4c; /* 활성화 시 강조 */
}

.timeline-step h4 {
  margin-top: 10px;
  font-size: 18px;
  color: #fff;
}

.timeline-step p {
  font-size: 14px;
  color: #fff;
}

.step-content {
  text-align: left;
  width: 70%;
  padding: 20px;
  background: #1a1a1a; /* 내용 배경 어둡게 */
  color: #fff;
  border-left: 2px solid #4c4c4c; /* 경계선 추가 */
  border-radius: 0 10px 10px 0;
}

.step-content h3 {
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #ff8c00;
}

.content-section h5 {
  font-size: 17px;
  color: #fff; /* 카테고리 제목은 주황색 */
}

.content-section h6 {
  margin-left:33px;
  font-size: 19px;
  color: #fff; /* 카테고리 제목은 주황색 */
}

.content-section ul {
  list-style: disc;
  margin-left: 20px;
}

.content-section li {
  font-size: 16px;
  margin-bottom: 5px;
  color: #fff;
  border-bottom: 1px solid #4c4c4c; /* 항목 경계선 */
  padding-bottom: 5px;
}
