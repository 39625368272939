* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  height: 100vh;
}

/* Hero 섹션 */
.hero-section {
  position: relative;
  width: 100%;
  height: 50vh;  
  display: flex;
  align-items: center;  
  justify-content: center;  
  text-align: center;
  color: white;
  overflow: hidden;
}

/* Swiper 컨테이너 */
.hero-section .image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.hero-section .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  opacity: 1.0;
  transition: opacity 1s ease;
}

/* 텍스트 내용 */
.hero-section .hero-content {
  position: absolute;
  z-index: 1;
  color: #fff;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7); 
}

.hero-section h2 {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 350px;
}

.hero-section p {
  font-size: 30px;
  margin-bottom: 10px;  
}
