
/* 공통 애니메이션 스타일 */
.conclusion-box {
  opacity: 0; /* 초기 상태 */
  transform: translateY(20px); /* 아래에서 위로 올라오는 효과 */
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.conclusion-box.visible {
  opacity: 1; /* 화면에 들어오면 보이게 설정 */
  transform: translateY(0); /* 제자리로 이동 */
}

.conclusion-box {
  animation: fadeInUp 0.6s ease-out forwards; /* 애니메이션 지정 */
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.conclusion-section {
  text-align: center;
  padding: 50px 20px;
  background-color: #000; 
  color: white;
}

.conclusion {
    font-size: 23px;
    margin-bottom: 20px;
    color: #cacaca;
}

.conclusion-title {
  margin-top: 60px;
  font-size: 23px;
  color: #ff8c00; 
  margin-bottom: 20px;
}

.conclusion-description {
  font-size: 34px;
  color: white; 
  margin-bottom: 10px;
}

.highlight-box {
  background-color: #3c2e24; /* 배경색 설정 */
  border: 2px solid #ff8c00; /* 테두리 색상 및 두께 설정 */
  padding: 20px; /* 내부 여백 설정 */
  border-radius: 8px; /* 모서리를 둥글게 설정 */
  color: #ff8c00; /* 텍스트 색상 설정 */
  text-align: center; /* 텍스트 중앙 정렬 */
  margin: 20px 0; /* 상하 마진 설정 */
}

.highlight-box h5 {
  font-size: 20px; 
  font-weight: bold; 
  margin-bottom: 10px; 
  color: #ff8c00; 
}

.highlight-box p {
  color: white; 
  font-size: 17px; 
  margin: 0; 
}

.conclusion-boxes {
  max-width: 1200px;
  display: flex;
  justify-content: center; 
  gap: 20px; 
  flex-wrap: wrap; 
  margin: 0 auto 100px;
}

.conclusion-box {
  background-color: #333; 
  padding: 20px;
  border-radius: 8px;
  width: 30%; 
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex; 
  flex-direction: column; 
  justify-content: space-between; 
  align-items: center; 
  min-height: 250px; 
}

.box-image {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
  border-radius: 5px;
}

.box-description {
  font-size: 16px;
  color: white; 
  margin-top:auto;
}


@media (max-width: 768px) {
  .conclusion-boxes {
    flex-direction: column; 
    gap: 20px; 
  }

  .conclusion-box {
    width: 100%; 
  }
}
